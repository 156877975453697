import React from "react"

import { Cards } from "../components"
import Layout from "../components/Layout"

import { data } from "../constants/blogPosts"

const Journal = () => {
  const [blogs] = React.useState(data)

  return (
    <Layout>
      {/* <Cards title="Journal" cards={blogs} collection="recent" /> */}
    </Layout>
  )
}

export default Journal
